import { ProductBundleType, ProductType } from "types";
import { hasCommonElement, shuffleArray } from "./array";

import products from "assets/products";

export const getNormalizedBundleProduct = (product: ProductBundleType) => {
  const categories = [];
  const groups = [];
  (product as ProductBundleType).products.forEach((p) => {
    p.categories.forEach((c) => {
      if (!categories.includes(c)) {
        categories.push(c);
      }
    });
    p.groups.forEach((c) => {
      if (!groups.includes(c)) {
        groups.push(c);
      }
    });
  });
  return { ...product, categories, groups };
};

export const getNormalizedProducts = () => {
  const normalizedProducts = products.map((product) => {
    if (product.isBundle) {
      return getNormalizedBundleProduct(product as ProductBundleType);
    }
    return product;
  });

  return normalizedProducts;
};

export enum ProductsIncludeBundles {
  "only" = "only",
  "include" = "include",
  "exclude" = "exclude",
}

export const getProducts = (props: {
  includeBundles?: ProductsIncludeBundles;
  categoryId?: string | string[];
  groupId?: string | string[];
  productId?: string | string[];
  excludeProductId?: string | string[];
  limit?: {
    maxCount: number;
    randomized?: boolean;
  };
}) => {
  const { categoryId, groupId, productId, excludeProductId } = props;

  const includeBundles = props.includeBundles || ProductsIncludeBundles.exclude;

  let relevantProducts = [...getNormalizedProducts()].filter((product) => {
    /* REMOVE BUNDLE IF THEY SHOULD BE EXCLUDED */
    if (
      (product as ProductType).isBundle &&
      includeBundles === ProductsIncludeBundles.exclude
    ) {
      return false;
    }

    /* REMOVE PRODUCT IF ONLY BUNDLES ARE REQUESTED */
    if (
      !(product as ProductType).isBundle &&
      includeBundles === ProductsIncludeBundles.only
    ) {
      return false;
    }

    /* REMOVE PRODUCT IF IT IS NOT OF SPECIFIED CATEGORY ID */
    if (categoryId) {
      const categoryIds = (product as ProductType).categories.map((c) => c.id);

      // handle categoryId as a single string
      if (typeof categoryId === "string" && !categoryIds.includes(categoryId)) {
        return false;
      }

      // handle categoryId as an array of strings (category ids)
      if (
        typeof categoryId !== "string" &&
        !hasCommonElement(categoryIds, categoryId)
      ) {
        return false;
      }
    }

    /* REMOVE PRODUCT IF IT IS NOT OF SPECIFIED GROUP ID */
    if (groupId) {
      const groupIds = (product as ProductType).groups.map((g) => g.id);

      // handle groupId as a single string
      if (typeof groupId === "string" && !groupIds.includes(groupId)) {
        return false;
      }

      // handle groupId as an array of strings (category ids)
      if (typeof groupId !== "string" && !hasCommonElement(groupIds, groupId)) {
        return false;
      }
    }

    /* REMOVE PRODUCT IF IT IS NOT OF SPECIFIED PRODUCT ID */
    if (productId) {
      if (product.isBundle) {
        //
        // BUNDLE
        //

        const bundleProductIds = (product as ProductBundleType).products.map(
          (p) => p.id
        );

        // handle productId as a single string
        if (
          typeof productId === "string" &&
          !bundleProductIds.includes(productId)
        ) {
          return false;
        }

        // handle productId as an array of strings (category ids)
        if (
          typeof productId !== "string" &&
          !hasCommonElement(bundleProductIds, productId)
        ) {
          return false;
        }
      } else {
        //
        // PRODUCT
        //

        // handle productId as a single string
        if (typeof productId === "string" && product.id !== productId) {
          return false;
        }

        // handle productId as an array of strings (category ids)
        if (typeof productId !== "string" && !productId.includes(product.id)) {
          return false;
        }
      }
    }

    /* REMOVE PRODUCT IF IT IS OF SPECIFIED PRODUCT ID TO EXCLUDE */
    if (excludeProductId) {
      // handle excludeProductId as a single string
      if (
        typeof excludeProductId === "string" &&
        product.id === excludeProductId
      ) {
        return false;
      }

      // handle excludeProductId as an array of strings (category ids)
      if (
        typeof excludeProductId !== "string" &&
        excludeProductId.includes(product.id)
      ) {
        return false;
      }
    }

    /* KEEP THE PRODUCT IN THE ARRAY */
    return true;
  });

  if (props.limit && relevantProducts?.length > props.limit?.maxCount) {
    relevantProducts = shuffleArray(relevantProducts).slice(
      0,
      props.limit.maxCount
    );
  }

  return relevantProducts;
};
