import { ProductType } from "types";
import background from "./background.jpg";
import fileFormats from "assets/misc/fileFormats";
import { importAll } from "utils";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/19UG3AQULOJcRBY6Qnjp-mvHFVrNNFjEE/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "AAE-Fire-Fx",
  name: "AAE Fire Fx",
  description: `The product contains an .aae project with fire simulation. The main advantage of the project is the fact, that you don't need any plugins, because the fire is created using only default AAE effects. It was created in Adobe After Effects CS6. The goal of this project was to create as realistic fire as possible, using only default AAE effects.`,

  price: 15,
  previousPrice: 20,
  softwareRequired: ["Adobe After Effects"],

  categories: [productCategories.visualEffect],
  groups: [productGroups.misc],
  license: licenses.commercial,

  images: gallery,
  videos: [{ src: "https://www.youtube.com/watch?v=bAkyn2qWnes", note: "" }],

  contents: [
    { text: "Fire simulation project", format: fileFormats.aep },
    { text: "NO 3RD-PARTY PLUGINS NEEDED" },
  ],
  tags: ["2D Effect"],
};

export default data;
