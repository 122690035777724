import { ProductType } from "types";
import background from "./background.jpg";
import fileFormats from "assets/misc/fileFormats";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1fZRem6Raddsq2d4zKI0Mw7KJJmk0r9-p/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Tie-Bomber",
  name: "Tie Bomber",
  description: `The product contains a .blend file with a low-poly 3d model of Tie Bomber. Great for background action or large space battles! Almost every part of the model is uv-unwrapped, there are materials for the 'Cycles' render engine.`,

  price: 5,
  softwareRequired: ["Blender 2.71"],

  categories: [productCategories.file3DModel],
  groups: [productGroups.starWars],
  license: licenses.editorial,

  images: gallery,
  videos: [],

  contents: [
    { text: "3D model - Tie Bomber", format: fileFormats.blend },
    {
      text: "about",
      isDivider: true,
    },
    { text: "Made for Cycles render engine" },
  ],
  tags: ["3D Model", "Cycles Materials"],
};

export default data;
