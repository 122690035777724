import { ProductType } from "types";
import background from "./background.jpg";
import fileFormats from "assets/misc/fileFormats";
import { importAll } from "utils";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1mX8dzudxPwDah2TO7Wm4FjeugU8m9PoD/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Mechno-Leg",
  name: "Mechno Leg",
  description: `The product contains a .blend file with detailed 3D model of a mechanical leg prosthesis. The 3D model has a simple rig which can be used for animation. It has no materials. Please note that this is a VERY OLD project, do not judge the quality of my work by the quality of this particular model's geometry.`,

  price: 6,
  softwareRequired: ["Blender 2.71"],

  categories: [productCategories.rigged3DModel],
  groups: [productGroups.misc],
  license: licenses.commercial,

  images: gallery,
  videos: [{ src: "https://www.youtube.com/watch?v=7A1S2ZykfMM", note: "" }],

  contents: [
    { text: "3D model - Mechno Leg", format: fileFormats.blend },
    {
      text: "about",
      isDivider: true,
    },
    { text: "Simple rig included" },
    { text: "No materials or textures" },
  ],
  tags: ["3D Model", "Simple Rig"],
};

export default data;
