import { ReplicaType } from "types";

export const sortReplicasByDate = (props: { replicas: ReplicaType[] }) => {
  let sortedReplicas: ReplicaType[] = [...props.replicas];
  // then sort by dateCreated (in case multiple replicas are published on the same date, they should be sorted by dateCreated)
  sortedReplicas = sortedReplicas.sort((a, b) =>
    (a.versions[0].datePublished || new Date(0, 0, 0).getTime()) <
    (b.versions[0].datePublished || new Date(0, 0, 0).getTime())
      ? 1
      : -1
  );
  // first sort by datePublished
  sortedReplicas = sortedReplicas.sort((a, b) =>
    (a.versions[0].dateCreated || new Date(0, 0, 0).getTime()) <
    (b.versions[0].dateCreated || new Date(0, 0, 0).getTime())
      ? 1
      : -1
  );

  return sortedReplicas;
};

export const sortReplicasByPreferredIndex = (props: {
  replicas: ReplicaType[];
}) => {
  let sortedReplicas: ReplicaType[] = [...props.replicas];

  // first sort by datePublished
  sortedReplicas = sortedReplicas.sort((a, b) => {
    // if preferredIndex exists, increment it by 1 to avoid having to account for '=== 0' cases in conditions below
    const aSort =
      a.sort?.preferredIndex || a.sort?.preferredIndex === 0
        ? a.sort?.preferredIndex + 1
        : undefined;
    const bSort =
      b.sort?.preferredIndex || b.sort?.preferredIndex === 0
        ? b.sort?.preferredIndex + 1
        : undefined;

    if (aSort && !bSort) {
      return -1;
    }
    if (!aSort && bSort) {
      return 1;
    }
    return aSort < bSort ? -1 : 1;
  });

  return sortedReplicas;
};
