import {
  ADD_TO_CART_REQUEST,
  addToCartFailed,
  addToCartSucceed,
  REMOVE_FROM_CART_REQUEST,
  removeFromCartFailed,
  removeFromCartSucceed,
  CLEAR_CART_REQUEST,
  clearCartFailed,
  clearCartSucceed,
} from './actions'
import { put, takeEvery } from 'redux-saga/effects'

function* addToCart(action) {
  try {
    yield put(addToCartSucceed(action.payload))
  } catch (e) {
    console.log(e)
    yield put(addToCartFailed())
  }
}

function* removeFromCart(action) {
  try {
    yield put(removeFromCartSucceed(action.payload))
  } catch (e) {
    console.log(e)
    yield put(removeFromCartFailed())
  }
}

function* clearFromCart(action) {
  try {
    yield put(clearCartSucceed())
  } catch (e) {
    console.log(e)
    yield put(clearCartFailed())
  }
}

const saga = [
  takeEvery(ADD_TO_CART_REQUEST, addToCart),
  takeEvery(REMOVE_FROM_CART_REQUEST, removeFromCart),
  takeEvery(CLEAR_CART_REQUEST, clearFromCart),
]

export default saga
