import { SpriteDirection, SpriteType } from "types";
import styled, { css } from "styled-components";
import { useEffect, useRef, useState } from "react";

import useImageLoaded from "hooks/useImageLoaded";
import useOnWindowResize from "hooks/useOnWindowResize";

type Dimensions = {
  width: number;
  height: number;
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
`;

const ImageWrapper = styled.div<{
  isLoaded: boolean | undefined;
  loadingStyle: LoadingStyle;
}>`
  position: relative;
  height: 100%;
  overflow: hidden;

  ${(props) =>
    props.loadingStyle &&
    css`
      ${props.loadingStyle.cssDefault};
    `}

  ${(props) =>
    props.loadingStyle &&
    props.isLoaded !== undefined &&
    !props.isLoaded &&
    css`
      ${props.loadingStyle.cssBeforeLoad};
    `}
`;

const Image = styled.img<{
  hasSprite: boolean;
}>`
  width: 100%;
  height: 100%;
  object-fit: contain;

  ${(props) =>
    props.hasSprite
      ? css`
          opacity: 0;
        `
      : css`
          width: 100%;
        `}
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Sprite = styled.img<{
  frameCount: number;
  activeFrame: number;
  direction: SpriteDirection;
  dimensions: Dimensions;
}>`
  position: absolute;
  top: 0;
  left: 0;

  ${(props) =>
    props.direction === SpriteDirection.vertical
      ? css`
          width: 100%;
          transform: translateY(
            calc(-1 * ${props.dimensions.height}px * ${props.activeFrame})
          );
        `
      : css`
          height: 100%;
          transform: translateX(
            calc(-1 * ${props.dimensions.width}px * ${props.activeFrame})
          );
        `}
`;

export enum AnimatedSpritePlay {
  "infinite" = "infinite",
  "once" = "once",
  "onceReversed" = "onceReversed",
}

type AnimationType = {
  index: number;
  loopCount: number;
  doIncrement: boolean;
};

type LoadingStyle = {
  cssBeforeLoad: string;
  cssDefault: string;
};

const AnimatedSprite = (props: {
  src: string | SpriteType;
  alt?: string;
  animation?: {
    play: AnimatedSpritePlay | undefined;
    frameDuration_Milliseconds?: number;
  };
  loadingStyle?: LoadingStyle;
  onLoad?: () => void;
  onFirstFrame?: () => void;
  onFirstFrameEnd?: () => void;
  onLastFrame?: () => void;
  onLastFrameEnd?: () => void;
  renderOverlay?: () => React.ReactNode;
}) => {
  const { imageRef: staticRef, imageLoaded: staticLoaded } = useImageLoaded();
  const { imageRef: spriteRef, imageLoaded: spriteLoaded } = useImageLoaded();

  let timerRef = useRef<any>();

  let spriteObject: SpriteType =
    typeof props.src === "object"
      ? props.src
      : {
          static: props.src,
          sprite: undefined,
          frameCount: 1,
          direction: SpriteDirection.horizontal,
        };

  //
  // GET FRAME DIMENSIONS
  //
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  const updateDimensions = () => {
    if (staticRef.current) {
      const d = staticRef.current;
      setDimensions({
        width: d.clientWidth,
        height: d.clientHeight,
      });
    }
  };

  useEffect(() => {
    updateDimensions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticLoaded]);

  useOnWindowResize(() => {
    updateDimensions();
  });

  //
  // ANIMATION
  //
  const play = props.animation?.play;
  const frameDuration = props.animation?.frameDuration_Milliseconds || 50;

  const [animation, setAnimation] = useState<AnimationType>({
    index: 0,
    loopCount: 0,
    doIncrement: false,
  });

  const limitIndexToFrameRange = (index) => {
    let newIndex = index % spriteObject.frameCount;

    // Ensure newIndex is positive
    if (newIndex < 0) {
      newIndex += spriteObject.frameCount;
    }
    return newIndex;
  };

  const incrementFrame = (step, currAnimation: AnimationType) => {
    timerRef.current = setTimeout(() => {
      const prevIndex = currAnimation?.index;
      let newIndex = prevIndex;
      let prevLoopCount = currAnimation?.loopCount;
      let newLoopCount = prevLoopCount;
      let doIncrement = false;

      if (prevIndex + step >= spriteObject.frameCount) {
        newLoopCount = prevLoopCount + 1;
      } else if (prevIndex + step < 0) {
        newLoopCount = prevLoopCount - 1;
      }

      switch (play) {
        case AnimatedSpritePlay.infinite:
          newIndex = prevIndex + step;
          doIncrement = true;
          break;
        case AnimatedSpritePlay.once:
          newIndex = prevIndex + step;
          if (newIndex + step < spriteObject.frameCount) {
            doIncrement = true;
          }
          break;
        case AnimatedSpritePlay.onceReversed:
          newIndex = prevIndex + step;
          if (newIndex + step >= 0) {
            doIncrement = true;
          }
          break;
      }

      setAnimation({
        index: limitIndexToFrameRange(newIndex),
        loopCount: newLoopCount,
        doIncrement: doIncrement,
      });
    }, frameDuration);
  };

  useEffect(() => {
    if (play && spriteObject.frameCount > 1) {
      if (!animation.doIncrement) {
        if (animation?.index === 0 && props.onFirstFrame) {
          props.onFirstFrame();
        }
        if (
          animation?.index === spriteObject.frameCount - 1 &&
          props.onLastFrame
        ) {
          props.onLastFrame();
        }
      }

      if (animation.doIncrement) {
        if (animation?.index === 0 && props.onFirstFrameEnd) {
          props.onFirstFrameEnd();
        }
        if (
          animation?.index === spriteObject.frameCount - 1 &&
          props.onLastFrameEnd
        ) {
          props.onLastFrameEnd();
        }
        switch (play) {
          case AnimatedSpritePlay.infinite:
          case AnimatedSpritePlay.once:
            incrementFrame(1, animation);
            break;
          case AnimatedSpritePlay.onceReversed:
            incrementFrame(-1, animation);
            break;
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  useEffect(() => {
    if (
      dimensions?.width &&
      dimensions?.height &&
      spriteLoaded &&
      spriteObject.frameCount > 1 &&
      play
    ) {
      switch (play) {
        case AnimatedSpritePlay.infinite:
        case AnimatedSpritePlay.once:
          setAnimation({
            index: 0,
            loopCount: 0,
            doIncrement: true,
          });
          break;
        case AnimatedSpritePlay.onceReversed:
          setAnimation({
            index: spriteObject.frameCount - 1,
            loopCount: 1,
            doIncrement: true,
          });
          break;
      }
    }
    return () => {
      clearTimeout(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spriteLoaded, staticLoaded, play, dimensions]);

  //
  // HANDLE ON LOAD EVENT
  //
  useEffect(() => {
    if (
      props.onLoad &&
      staticLoaded &&
      (spriteObject.sprite
        ? spriteLoaded && dimensions?.width && dimensions?.height
        : true)
    ) {
      props.onLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticLoaded, spriteLoaded, dimensions]);

  //
  // RENDER
  //
  return (
    <Wrapper>
      <ImageWrapper
        isLoaded={spriteObject.sprite ? spriteLoaded : staticLoaded}
        loadingStyle={props.loadingStyle}
      >
        <Image
          src={spriteObject.static}
          alt={props.alt}
          hasSprite={!!spriteObject.sprite}
          ref={staticRef}
        />

        {spriteObject.sprite ? (
          <Sprite
            ref={spriteRef}
            src={spriteObject.sprite}
            alt={props.alt}
            direction={spriteObject.direction}
            dimensions={dimensions}
            frameCount={spriteObject.frameCount}
            activeFrame={animation?.index}
          />
        ) : null}

        {props.renderOverlay && spriteLoaded ? (
          <Overlay>{props.renderOverlay()}</Overlay>
        ) : null}
      </ImageWrapper>
    </Wrapper>
  );
};

export default AnimatedSprite;
