import {
  Adobeaftereffects,
  Adobephotoshop,
  Blender,
} from "@styled-icons/simple-icons";
import {
  FileJpg,
  FilePdf,
  FilePlus,
  FilePng,
} from "@styled-icons/boxicons-solid";

import { FileAudio } from "@styled-icons/fa-solid";
import { FileFormatType } from "types";
import { LocalMovies } from "@styled-icons/material-rounded";
import { SelectObject } from "@styled-icons/fluentui-system-filled";

const fileFormats: {
  aep: FileFormatType;
  blend: FileFormatType;
  stl: FileFormatType;
  prusaSlicer: FileFormatType;
  obj: FileFormatType;
  pdf: FileFormatType;
  jpg: FileFormatType;
  png: FileFormatType;
  psd: FileFormatType;
  mov: FileFormatType;
  wmv: FileFormatType;
  mp3: FileFormatType;
  files: FileFormatType;
} = {
  aep: {
    id: "aep",
    name: ".aep",
    description: "Adobe After Effects Project",
    icon: <Adobeaftereffects />,
  },
  blend: {
    id: "blend",
    name: ".blend",
    description: "Blender Project",
    icon: <Blender />,
  },
  stl: {
    id: "stl",
    name: ".stl",
    description: "STL 3D Model",
    icon: <SelectObject />,
  },
  prusaSlicer: {
    id: "prusaSlicer",
    name: ".3mf",
    description: "PrusaSlicer Project",
    icon: <SelectObject />,
  },
  obj: {
    id: "obj",
    name: ".obj",
    description: "Obj 3D Model",
    icon: <SelectObject />,
  },
  pdf: {
    id: "pdf",
    name: ".pdf",
    description: "PDF File",
    icon: <FilePdf />,
  },
  jpg: {
    id: "jpg",
    name: ".jpg",
    description: "JPG File",
    icon: <FileJpg />,
  },
  png: {
    id: "png",
    name: ".png",
    description: "PNG File",
    icon: <FilePng />,
  },
  psd: {
    id: "psd",
    name: ".psd",
    description: "PSD File",
    icon: <Adobephotoshop />,
  },
  mov: {
    id: "mov",
    name: ".mov",
    description: "MOV File",
    icon: <LocalMovies />,
  },
  wmv: {
    id: "wmv",
    name: ".wmv",
    description: "WMV File",
    icon: <LocalMovies />,
  },
  mp3: {
    id: "mp3",
    name: ".mp3",
    description: "MP3 File",
    icon: <FileAudio />,
  },
  files: {
    id: "files",
    name: "files",
    description: "Misc Files",
    icon: <FilePlus />,
  },
};

export default fileFormats;
