import { ProductType } from "types";
import background from "./background.jpg";
import fileFormats from "assets/misc/fileFormats";
import { importAll } from "utils";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1hfCxkTMLx5KiiBSWuSj-NtvgoXITgkT5/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Nightcrawler-Fx-Pack",
  name: "Nightcrawler Fx-Pack",
  description: `The product contains an .obj file with a 3d model of a prison, which can be imported to common 3d software, such as 3Ds Max, Cinema 4D, Maya, Blender etc. It is great for holographic environments, call-of-duty-like mission briefing videos, hud schematics and much more!`,

  price: 15,
  previousPrice: 20,
  softwareRequired: [],

  categories: [productCategories.visualEffect],
  groups: [productGroups.misc],
  license: licenses.commercial,

  images: gallery,
  videos: [{ src: "https://www.youtube.com/watch?v=DC5uxFhNc1k", note: "" }],

  contents: [
    {
      text: "10x Smoke simulation (ground interaction)",
      format: fileFormats.mov,
    },
    { text: "5x Smoke simulation (no ground)", format: fileFormats.mov },
    { text: "5x Smoke simulation (wind/movement)", format: fileFormats.mov },
    { text: "20 Sound effects", format: fileFormats.mp3 },
    { text: "Test Footage", format: fileFormats.wmv },
    {
      text: "Teleportation Tutorial for Adobe After Effects",
      format: fileFormats.pdf,
    },
    { text: "Rigged CGI tail", format: fileFormats.blend },
  ],
  tags: ["2D Effect"],
};

export default data;
