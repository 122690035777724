import { useState, useEffect } from 'react'

const useScrollAppear = (props: { triggerHeightPercentage: number }) => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }

  const [scrolled, setScrolled] = useState(0)
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }
    const handleScrolled = () => {
      setScrolled(window.scrollY)
    }

    window.addEventListener('scroll', handleScrolled)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('scroll', handleScrolled)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    scrolled > (windowDimensions.height * props.triggerHeightPercentage) / 100
  )
}

export default useScrollAppear
