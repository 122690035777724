import { RefObject, useEffect, useRef, useState } from "react";

type UseImageLoadedReturn = {
  imageRef: RefObject<HTMLImageElement>;
  imageLoaded: boolean;
};

const useImageLoaded = (props?: {
  onLoad?: () => void;
}): UseImageLoadedReturn => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setImageLoaded(true);
      if (props?.onLoad) {
        props.onLoad();
      }
    };

    const imageElement = imageRef.current;

    if (imageElement) {
      if (imageElement.complete) {
        handleLoad();
      } else {
        imageElement.addEventListener("load", handleLoad);
        return () => {
          imageElement.removeEventListener("load", handleLoad);
        };
      }
    }
  }, [props]);

  return { imageRef, imageLoaded };
};

export default useImageLoaded;
