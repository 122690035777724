import { RefObject, useEffect, useState } from "react";

interface Props {
  elementHeightRef?: RefObject<HTMLElement>;
}

const useScrollPercentage = ({ elementHeightRef }: Props = {}) => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const [scrolled, setScrolled] = useState(0);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    const handleScrolled = () => {
      let scrollTop = window.scrollY;
      let scrollPercent = 0;

      if (elementHeightRef && elementHeightRef.current) {
        let element = elementHeightRef.current;
        let elementHeight = element.offsetHeight;
        let winHeight = windowDimensions.height;
        let elementOffsetTop = element.offsetTop;
        let visiblePart = winHeight - (scrollTop - elementOffsetTop);

        scrollPercent = 0;

        if (scrollTop > elementOffsetTop) {
          scrollPercent = 100 - (visiblePart / elementHeight) * 100;
        } else {
          scrollPercent = 0;
        }
      } else {
        let docHeight = document.body.offsetHeight;
        let winHeight = windowDimensions.height;
        scrollPercent = (scrollTop / (docHeight - winHeight)) * 100;
      }

      setScrolled(scrollPercent);
    };

    window.addEventListener("scroll", handleScrolled);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScrolled);
      window.removeEventListener("resize", handleResize);
    };
  }, [elementHeightRef, windowDimensions]);

  return scrolled;
};

export default useScrollPercentage;
