import { useEffect, useRef } from "react";

import styled from "styled-components";
import { useOnScreen } from "hooks";

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

const Anchors = styled.div`
  position: absolute;
  top: 0;
  width: 1px;
  height: 99%;
`;

const ScrollAnchor = styled.div`
  position: absolute;
  top: 0px;
  height: 1px;
  width: 1px;
`;
const OnScreenAnchor = styled.div`
  --height: 50%;
  position: absolute;
  top: calc((100% - var(--height)) / 2);
  height: var(--height);
  width: 1px;
`;

const Section = (props: {
  id: string;
  label: string;
  component: any;
  scrollId: string;
  onVisibleChange: (
    id: string,
    isVisible: boolean,
    cssOnFullScroll?: string
  ) => void;
}) => {
  const refScroll = useRef();
  const refOnScreen = useRef();
  const onScreen = useOnScreen(refOnScreen);

  useEffect(() => {
    if (props.scrollId === props.id && refScroll?.current) {
      // @ts-ignore: Object is possibly 'undefined'.
      refScroll.current.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scrollId]);

  useEffect(() => {
    props.onVisibleChange(props.id, onScreen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen]);

  return (
    <Wrapper>
      <Anchors>
        <ScrollAnchor ref={refScroll} id={props.id} />
        <OnScreenAnchor ref={refOnScreen} />
      </Anchors>
      {props.component}
    </Wrapper>
  );
};

export default Section;
