import styled, { css } from 'styled-components'

const Static = styled.div`
  position: fixed;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  margin-left: 4%;

  ${props => props.theme.breakpoint.L} {
    display: none;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Section = styled.div<{ isActive: boolean }>`
  padding: 6px 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  ${({ isActive }) =>
    isActive
      ? css`
          padding: 40px 0;
        `
      : css``}
`

const SectionMark = styled.div<{ isActive: boolean }>`
  --size: 6px;
  width: var(--size);
  height: var(--size);
  border-radius: 1px;
  border: 1px solid ${props => props.theme.white}33;

  transition: all 0.2s ease;

  ${({ isActive }) =>
    isActive
      ? css`
          background: ${props => props.theme.white};
        `
      : css``}
`

const SectionName = styled.div<{ isActive: boolean }>`
  transition: all 0.2s ease;
  margin-left: 8px;
  font-size: 9px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 1px 10px;
  background: ${props => props.theme.white}BB;
  color: ${props => props.theme.black};

  /* ${({ isActive }) =>
    isActive
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0.05;
        `} */

  opacity: 1;
  transform: scaleX(0);
  transform-origin: 0px;
  ${Section}:hover & {
    opacity: 1;
    transform: scaleX(1);
  }
`

const Navigation = (props: {
  sections: { label: string; id: string }[]
  activeId: string
  onItemClick: (label: string) => void
}) => {
  const isActive = (section: { id: string }) => section.id === props.activeId

  return (
    <Static>
      <Wrapper>
        {props.sections.map(section => (
          <Section
            key={section.id}
            isActive={isActive(section)}
            onClick={() => props.onItemClick(section.id)}
          >
            <SectionMark isActive={isActive(section)} />
            <SectionName isActive={isActive(section)}>
              {section.label}
            </SectionName>
          </Section>
        ))}
      </Wrapper>
    </Static>
  )
}

export default Navigation
