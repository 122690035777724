import {
  ADD_PAYPAL_ORDER_REQUEST,
  addPayPalOrderFailed,
  addPayPalOrderSucceed,
  REMOVE_PAYPAL_ORDER_REQUEST,
  removePayPalOrderFailed,
  removePayPalOrderSucceed,
} from './actions'
import { put, takeEvery } from 'redux-saga/effects'

function* addPayPalOrder(action) {
  try {
    yield put(addPayPalOrderSucceed(action.payload))
  } catch (e) {
    console.log(e)
    yield put(addPayPalOrderFailed())
  }
}

function* removePayPalOrder(action) {
  try {
    yield put(removePayPalOrderSucceed(action.payload))
  } catch (e) {
    console.log(e)
    yield put(removePayPalOrderFailed())
  }
}

const saga = [
  takeEvery(ADD_PAYPAL_ORDER_REQUEST, addPayPalOrder),
  takeEvery(REMOVE_PAYPAL_ORDER_REQUEST, removePayPalOrder),
]

export default saga
