import {
  AnyProductType,
  ProductAAEHUDFactoryType,
  ProductBundleType,
  ProductType,
} from "types";

import AAEFireFx from "assets/products/individual/AAE-Fire-Fx";
import AAEPack from "assets/products/_bundles/AAE-Pack";
import AzazelFxPack from "assets/products/individual/Azazel-Fx-Pack";
import BarretM82a1Remote from "assets/products/individual/Barret-M82a1-Remote";
import BionicArm from "assets/products/individual/Bionic-Arm";
import CustomHUD from "assets/products/aae-hud-factory-v2/Custom-HUD";
import GoldenSnitch3DModel from "assets/products/individual/Golden-Snitch-3D-Model";
import GoldenSnitchRigged from "assets/products/individual/Golden-Snitch-Rigged";
import HUDEffects from "assets/products/aae-hud-factory-v2/HUD-Effects";
import ImperialFleet from "assets/products/_bundles/Imperial-Fleet";
import IronMan_BUNDLES from "assets/products/_bundles/IronMan-BUNDLES";
import LowPolySpaceships from "assets/products/individual/Low-Poly-Spaceships";
import Mark23DModel from "assets/products/individual/Mark-2-3D-Model";
import Mark2Blueprints from "assets/products/individual/Mark-2-Blueprints";
import Mark2HUD from "assets/products/aae-hud-factory-v2/Mark-2-HUD";
import Mark2Suit from "assets/products/individual/Mark-2-Suit";
import Mark2SuitCycles from "assets/products/individual/Mark-2-Suit-Cycles";
import Mark33DModel from "assets/products/individual/Mark-3-3D-Model";
import Mark3Blueprints from "assets/products/individual/Mark-3-Blueprints";
import Mark3HUD from "assets/products/aae-hud-factory-v2/Mark-3-HUD";
import Mark3Suit from "assets/products/individual/Mark-3-Suit";
import Mark3SuitCycles from "assets/products/individual/Mark-3-Suit-Cycles";
import Mark43DModel from "assets/products/individual/Mark-4-3D-Model";
import Mark4Blueprints from "assets/products/individual/Mark-4-Blueprints";
import Mark4HUD from "assets/products/aae-hud-factory-v2/Mark-4-HUD";
import Mark4Suit from "assets/products/individual/Mark-4-Suit";
import Mark4SuitCycles from "assets/products/individual/Mark-4-Suit-Cycles";
import Mark53DModel from "assets/products/individual/Mark-5-3D-Model";
import Mark5Blueprints from "assets/products/individual/Mark-5-Blueprints";
import Mark5HUD from "assets/products/aae-hud-factory-v2/Mark-5-HUD";
import Mark5Suit from "assets/products/individual/Mark-5-Suit";
import Mark63DModel from "assets/products/individual/Mark-6-3D-Model";
import Mark6Blueprints from "assets/products/individual/Mark-6-Blueprints";
import Mark6HUD from "assets/products/aae-hud-factory-v2/Mark-6-HUD";
import Mark6Suit from "assets/products/individual/Mark-6-Suit";
import MechnoLeg from "assets/products/individual/Mechno-Leg";
import MongerHUD from "assets/products/aae-hud-factory-v2/Monger-HUD";
import NightcrawlerAndAzazel from "assets/products/_bundles/Nightcrawler-And-Azazel";
import NightcrawlerFxPack from "assets/products/individual/Nightcrawler-Fx-Pack";
import ParallaxHUD from "assets/products/aae-hud-factory-v2/Parallax-HUD";
import PrintableGraflexFlashgun from "assets/products/3d-printables/SW-Printable-Graflex-Flashgun";
import PrintableLightsaberAnakinEp3 from "assets/products/3d-printables/SW-Printable-Lightsaber-Anakin-Ep3";
import PrintableLightsaberDookuEp3 from "assets/products/3d-printables/SW-Printable-Lightsaber-Dooku-Ep3";
import PrintableLightsaberLukeEp4 from "assets/products/3d-printables/SW-Printable-Lightsaber-Luke-Ep4";
import PrintableLightsaberLukeEp5 from "assets/products/3d-printables/SW-Printable-Lightsaber-Luke-Ep5";
import PrintableLightsaberLukeEp6_Hero from "assets/products/3d-printables/SW-Printable-Lightsaber-Luke-Ep6-hero";
import PrintableLightsaberLukeEp6_V2 from "assets/products/3d-printables/SW-Printable-Lightsaber-Luke-Ep6-v2";
import PrintableLightsaberLukeEp6_V3 from "assets/products/3d-printables/SW-Printable-Lightsaber-Luke-Ep6-v3";
import PrintableLightsaberMaceEp3 from "assets/products/3d-printables/SW-Printable-Lightsaber-Mace-Ep3";
import PrintableLightsaberObiWanEp3 from "assets/products/3d-printables/SW-Printable-Lightsaber-Obi-Wan-Ep3";
import PrintableLightsaberObiWanEp4 from "assets/products/3d-printables/SW-Printable-Lightsaber-Obi-Wan-Ep4";
import PrintableLightsaberVaderEp4 from "assets/products/3d-printables/SW-Printable-Lightsaber-Vader-Ep4";
import PrintableLightsaberVaderEp5 from "assets/products/3d-printables/SW-Printable-Lightsaber-Vader-Ep5";
import PrintableLightsaberVaderEp6 from "assets/products/3d-printables/SW-Printable-Lightsaber-Vader-Ep6";
import PrintableLightsaber_BUNDLES from "assets/products/_bundles/SW-Printable-Lightsaber-BUNDLES";
import PrintableMPPFlashgun from "assets/products/3d-printables/SW-Printable-MPP-Flashgun";
import Prison3DModel from "assets/products/individual/Prison-3D-Model";
import Skeleton3DModel from "assets/products/individual/Skeleton-3D-Model";
import Skeleton3DModelRigged from "assets/products/individual/Skeleton-3D-Model-Rigged";
import StarDestroyer from "assets/products/individual/Star-Destroyer";
import TieAdvancedX1 from "assets/products/individual/Tie-Advanced-X1";
import TieBomber from "assets/products/individual/Tie-Bomber";
import TieFighter from "assets/products/individual/Tie-Fighter";
import TieInterceptor from "assets/products/individual/Tie-Interceptor";
import productCategories from "assets/products/_categories";

//
// ALL PRODUCTS
//
const productsIndividual: ProductType[] = [
  PrintableLightsaberLukeEp4,
  PrintableLightsaberLukeEp5,
  PrintableLightsaberLukeEp6_V2,
  PrintableLightsaberLukeEp6_V3,
  PrintableLightsaberLukeEp6_Hero,

  PrintableLightsaberVaderEp6,
  PrintableLightsaberVaderEp5,
  PrintableLightsaberVaderEp4,

  PrintableLightsaberObiWanEp4,
  PrintableLightsaberObiWanEp3,

  PrintableLightsaberAnakinEp3,
  PrintableLightsaberDookuEp3,
  PrintableLightsaberMaceEp3,

  PrintableGraflexFlashgun,
  PrintableMPPFlashgun,

  Skeleton3DModel,
  Skeleton3DModelRigged,
  BionicArm,

  Mark2Blueprints,
  Mark23DModel,
  Mark2Suit,
  Mark2SuitCycles,

  Mark3Blueprints,
  Mark33DModel,
  Mark3Suit,
  Mark3SuitCycles,

  Mark4Blueprints,
  Mark43DModel,
  Mark4Suit,
  Mark4SuitCycles,

  Mark5Blueprints,
  Mark53DModel,
  Mark5Suit,

  Mark6Blueprints,
  Mark63DModel,
  Mark6Suit,

  TieFighter,
  TieAdvancedX1,
  TieBomber,
  TieInterceptor,
  StarDestroyer,

  AAEFireFx,
  AzazelFxPack,
  NightcrawlerFxPack,
  BarretM82a1Remote,
  LowPolySpaceships,
  MechnoLeg,
  Prison3DModel,
  GoldenSnitch3DModel,
  GoldenSnitchRigged,
];

const productBundles: ProductBundleType[] = [
  ...PrintableLightsaber_BUNDLES,
  ...IronMan_BUNDLES,
  ImperialFleet,
  AAEPack,
  NightcrawlerAndAzazel,
];

const productsAAEHUDFactory: ProductAAEHUDFactoryType[] = [
  Mark2HUD,
  Mark3HUD,
  MongerHUD,
  Mark4HUD,
  Mark5HUD,
  Mark6HUD,
  ParallaxHUD,
  CustomHUD,
  HUDEffects,
];

const products: AnyProductType[] = [
  ...productsIndividual,
  ...productsAAEHUDFactory,
  ...productBundles,
];

export default products;
export { productsIndividual, productCategories, productsAAEHUDFactory };
