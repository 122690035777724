import { ProductBundleType } from "types";
import StarDestroyer from "assets/products/individual/Star-Destroyer";
import TieAdvancedX1 from "assets/products/individual/Tie-Advanced-X1";
import TieBomber from "assets/products/individual/Tie-Bomber";
import TieFighter from "assets/products/individual/Tie-Fighter";
import TieInterceptor from "assets/products/individual/Tie-Interceptor";
import background from "./background.jpg";
import { createBundle } from "../bundle-generator";
import { importAll } from "utils";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductBundleType = createBundle({
  id: "Imperial-Fleet",
  name: "Imperial Fleet",
  thumbnail,
  background,
  products: [
    TieFighter,
    TieAdvancedX1,
    TieBomber,
    TieInterceptor,
    StarDestroyer,
  ],

  images: gallery,
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/18s19BixZdM7r-_hRFrJUNfzeqORG76uq/view?usp=sharing",
  },
});

export default data;
