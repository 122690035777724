import {
  GeneratedBundleThumbnailType,
  ProductAAEHUDFactoryType,
  ProductBundleType,
  ProductFilesType,
  ProductType,
} from "types";

export const createBundle: (props: {
  id: string;
  name: string;
  products: (ProductType | ProductAAEHUDFactoryType)[];
  thumbnail?: string;
  background?: string;
  discountPercentage?: number;
  purchase?: ProductFilesType;
  images?: { src: string }[];
  generatedThumbnail?: GeneratedBundleThumbnailType;
}) => ProductBundleType = (props) => {
  const {
    id,
    name,
    thumbnail,
    generatedThumbnail,
    background,
    products,
    purchase,
    images,
  } = props;

  const discountPercentage = props.discountPercentage || 25;

  const data: ProductBundleType = {
    isBundle: true,
    thumbnail,
    generatedThumbnail,
    background: background || products[0]?.background,
    id,
    name,
    price: Math.round(
      products.map((p) => p.price).reduce((a, b) => a + b, 0) *
        (1 - discountPercentage / 100)
    ),
    products,
    purchase,
    images,
  };

  return data;
};
