export const paypal: {
  currency: string;
  clientId: { sandbox: string; production: string };
  fees: { fixedFee: number; percentageFee: number };
  sandbox: boolean;
} = {
  currency: "USD",
  clientId: {
    sandbox:
      "AZw_8944CD5cDPITtO5XEP5ldZaL-DFKKAgIgddc4NbqOUc-lOuCxL5PZ1WpFY7ZqDcKzXaPt0l9WDwS",
    production:
      "AUA99yzlP4AHb1IpWcERvjNoecOPn6nPW13D8BDfXJ-4-SLVk5hyNO5flLWKJVjD8uhhH7vyiwJbH97r",
  },
  fees: {
    fixedFee: 0.49, // USD (https://www.paypal.com/us/webapps/mpp/merchant-fees#fixed-fees-standard)
    percentageFee: 4.99, // (3.49% + 1.5% international) % (https://www.paypal.com/us/webapps/mpp/merchant-fees)
  },

  sandbox: process.env.REACT_APP_PAYPAL_USE_SANDBOX === "true", // FOR PRODUCTION SET TO FALSE // TODO
};
