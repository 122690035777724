import { ProductType, SpriteDirection } from "types";

import SHARED_PRINTABLE_INFO from "../shared-info";
import background from "./background.png";
import explodedView_Labels from "./exploded-view_labels.png";
import explodedView_Sprite from "./exploded-view_sprite.png";
import explodedView_Thumbnail from "./exploded-view_thumbnail.png";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
/* try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
} */

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1n3MVAY5gG8sUB0Z3xWaZa3jpw4QL5qUs/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Printable-Lightsaber-Mace-Ep3",
  name: "Mace Ep3 Lightsaber",
  description: () => (
    <>
      {SHARED_PRINTABLE_INFO.getPropDescription(
        `Mace Windu's lightsaber from Episode 3: Revenge of the Sith`
      )}{" "}
      {SHARED_PRINTABLE_INFO.getDiagramDescription()}
    </>
  ),

  price: SHARED_PRINTABLE_INFO.prices.lightsaber,
  softwareRequired: [],

  categories: [productCategories.file3DPrintable],
  groups: [productGroups.starWars],
  license: licenses.personal,

  images: gallery,
  videos: [],
  explodedView: {
    static: explodedView_Thumbnail,
    sprite: explodedView_Sprite,
    frameCount: 11,
    direction: SpriteDirection.vertical,
    labels: explodedView_Labels,
  },

  contents: [...SHARED_PRINTABLE_INFO.contents],
  tags: [...SHARED_PRINTABLE_INFO.tags],
};

export default data;
