import { ProductCategoryType } from "types";

const productCategories: {
  file3DPrintable: ProductCategoryType;
  file3DModel: ProductCategoryType;
  rigged3DModel: ProductCategoryType;
  blueprint: ProductCategoryType;
  visualEffect: ProductCategoryType;
  hud: ProductCategoryType;
} = {
  file3DPrintable: {
    id: "3d-printable",
    name: "3D Printables",
    description: "",
  },
  file3DModel: {
    id: "3d-model",
    name: "3D Models",
    description: "",
  },
  rigged3DModel: {
    id: "rigged-3d-model",
    name: "Rigged 3D Models / Projects",
    description: "",
  },
  blueprint: {
    id: "blueprint",
    name: "Blueprints",
    description: "",
  },
  hud: {
    id: "hud",
    name: "Heads-Up Displays",
    description: "",
  },
  visualEffect: {
    id: "visual-effects",
    name: "Visual Effects",
    description: "",
  },
};

export default productCategories;
