import { ProductType } from "types";
import background from "./background.jpg";
import fileFormats from "assets/misc/fileFormats";
import { importAll } from "utils";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1a6eeMfw7gdZm-60715E6Cl0TcOwAWKgq/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Skeleton-3D-Model-Rigged",
  name: "Skeleton 3D Model (Rigged)",
  description: `The product contains a 3d model of the skeleton, which can be used in graphical user interfaces for medical animations, diagnosis and much more. The model is exported to .obj file, so it can be used in common 3d programs, such as 3Ds Max, Cinema 4D, Maya, Blender etc. There are three .obj files, each one with different subdivision level. This version contains also a .blend file with the model attached to IK/FK rig ready for animations. It has no materials.`,

  price: 35,
  softwareRequired: ["Blender 2.81a"],

  categories: [productCategories.rigged3DModel],
  groups: [productGroups.misc],
  license: licenses.commercial,

  images: gallery,
  videos: [{ src: "https://www.youtube.com/watch?v=rx1Ct7_-aQM" }],

  contents: [
    { text: "Rigged Skeleton", format: fileFormats.blend },
    {
      text: "3D model - Skeleton - subdivision level 1",
      format: fileFormats.obj,
    },
    {
      text: "3D model - Skeleton - subdivision level 2",
      format: fileFormats.obj,
    },
    {
      text: "3D model - Skeleton - subdivision level 3",
      format: fileFormats.obj,
    },
    {
      text: "about",
      isDivider: true,
    },
    { text: "No materials or textures" },
  ],
  tags: ["Rigged", "3D Model"],
};

export default data;
