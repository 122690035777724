import {
  CartActionTypes,
  ADD_TO_CART_SUCCEED,
  REMOVE_FROM_CART_SUCCEED,
  CLEAR_CART_SUCCEED,
} from './actions'

import { AnyProductType } from 'types'

const initialState: AnyProductType[] = []

const reducer = (state = initialState, action: CartActionTypes) => {
  switch (action.type) {
    case ADD_TO_CART_SUCCEED:
      return [...state, action.payload]

    case REMOVE_FROM_CART_SUCCEED:
      return [
        ...state.filter(
          productInCart => productInCart.id !== action.payload.id
        ),
      ]

    case CLEAR_CART_SUCCEED:
      return []

    default:
      return state
  }
}

export default reducer
