import { ProductType } from "types";
import background from "./background.jpg";
import fileFormats from "assets/misc/fileFormats";
import { importAll } from "utils";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1rp5ZLPq2ux3yvOVsotYG27axOWTmYaX1/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Low-Poly-Spaceships",
  name: "Low Poly Spaceships",
  description: `The product contains a .blend file with five low-poly 3d models great for background action or large space battles! The product includes Sith Fighter, Lambda-class Shuttle and Theta-class Shuttle. There are materials for "Blender Render" engine.`,

  price: 6,
  softwareRequired: ["Blender 2.69"],

  categories: [productCategories.rigged3DModel],
  groups: [productGroups.starWars],
  license: licenses.editorial,

  images: gallery,
  videos: [{ src: "https://www.youtube.com/watch?v=Go31AqWCXUQ", note: "" }],

  contents: [
    { text: "3D model - Sith Fighter", format: fileFormats.blend },
    { text: "3D model - Lambda-class Shuttle", format: fileFormats.blend },
    { text: "3D model - Theta-class Shuttle", format: fileFormats.blend },
    {
      text: "about",
      isDivider: true,
    },
    { text: "Made for Blender Render engine" },
  ],
  tags: ["Rigged", "Materials", "Textures"],
};

export default data;
