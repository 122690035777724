export const hasCommonElement = (arr1: string[], arr2: string[]): boolean => {
  return arr1.filter((item) => arr2.includes(item)).length > 0;
};

export const shuffleArray = <T extends unknown>(array: T[]): T[] => {
  const shuffledArray = [...array]; // Create a copy of the array
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};
