import { PayPalOrderType } from 'types'

//
// ADD TO CART
//
export const ADD_PAYPAL_ORDER_REQUEST = 'ADD_PAYPAL_ORDER_REQUEST'
export const ADD_PAYPAL_ORDER_SUCCEED = 'ADD_PAYPAL_ORDER_SUCCEED'
export const ADD_PAYPAL_ORDER_FAILED = 'ADD_PAYPAL_ORDER_FAILED'

type AddPayPalOrderRequestType = {
  type: typeof ADD_PAYPAL_ORDER_REQUEST
  payload: PayPalOrderType
}
export const addPayPalOrder = (payload): AddPayPalOrderRequestType => ({
  type: ADD_PAYPAL_ORDER_REQUEST,
  payload,
})

type AddPayPalOrderSucceedType = {
  type: typeof ADD_PAYPAL_ORDER_SUCCEED
  payload: PayPalOrderType
}
export const addPayPalOrderSucceed = (payload): AddPayPalOrderSucceedType => ({
  type: ADD_PAYPAL_ORDER_SUCCEED,
  payload,
})

type AddPayPalOrderFailedType = {
  type: typeof ADD_PAYPAL_ORDER_FAILED
}
export const addPayPalOrderFailed = (): AddPayPalOrderFailedType => ({
  type: ADD_PAYPAL_ORDER_FAILED,
})

//
// REMOVE FROM CART
//
export const REMOVE_PAYPAL_ORDER_REQUEST = 'REMOVE_PAYPAL_ORDER_REQUEST'
export const REMOVE_PAYPAL_ORDER_SUCCEED = 'REMOVE_PAYPAL_ORDER_SUCCEED'
export const REMOVE_PAYPAL_ORDER_FAILED = 'REMOVE_PAYPAL_ORDER_FAILED'

type RemovePayPalOrderRequestType = {
  type: typeof REMOVE_PAYPAL_ORDER_REQUEST
  payload: PayPalOrderType
}
export const removePayPalOrder = (payload): RemovePayPalOrderRequestType => ({
  type: REMOVE_PAYPAL_ORDER_REQUEST,
  payload,
})

type RemovePayPalOrderSucceedType = {
  type: typeof REMOVE_PAYPAL_ORDER_SUCCEED
  payload: PayPalOrderType
}
export const removePayPalOrderSucceed = (
  payload
): RemovePayPalOrderSucceedType => ({
  type: REMOVE_PAYPAL_ORDER_SUCCEED,
  payload,
})

type RemovePayPalOrderFailedType = {
  type: typeof REMOVE_PAYPAL_ORDER_FAILED
}
export const removePayPalOrderFailed = (): RemovePayPalOrderFailedType => ({
  type: REMOVE_PAYPAL_ORDER_FAILED,
})

//
// ALL TYPES
//
export type PayPalActionTypes =
  | AddPayPalOrderRequestType
  | AddPayPalOrderSucceedType
  | AddPayPalOrderFailedType
  | RemovePayPalOrderRequestType
  | RemovePayPalOrderSucceedType
  | RemovePayPalOrderFailedType
