import { PayPalOrderStoredType, ProductBundleType, PurchaseType } from "types";

import products from "assets/products";

export const getPurchasesFromPayPalOrders = (
  paypalOrders: PayPalOrderStoredType[]
) => {
  if (paypalOrders?.length) {
    const purchases: PurchaseType[] = [];

    paypalOrders.forEach((paypalOrder) => {
      const purchase: PurchaseType = {
        orderDate: new Date(paypalOrder?.create_time),
        productsWithDownloadLinks: [],
        additionalDownloads: [],
      };

      // Get products with download links
      paypalOrder?.products?.forEach((orderProduct) => {
        if (orderProduct.isBundle && !orderProduct.purchase?.downloadLink) {
          (orderProduct as ProductBundleType).products?.forEach(
            (productInBundle) => {
              if (
                !purchase.productsWithDownloadLinks.find(
                  (p) => p.id === productInBundle.id
                )
              ) {
                purchase.productsWithDownloadLinks.push(productInBundle);
              }
            }
          );
        } else {
          if (
            !purchase.productsWithDownloadLinks.find(
              (p) => p.id === orderProduct.id
            )
          ) {
            purchase.productsWithDownloadLinks.push(orderProduct);
          }
        }
      });

      // Aggregate additional download links
      purchase.productsWithDownloadLinks.forEach((p) => {
        if (p.purchase?.additionalDownload?.downloadLink?.length) {
          const additionalDownload = p.purchase?.additionalDownload;
          if (
            !purchase.additionalDownloads.find(
              (d) => d.downloadLink === additionalDownload.downloadLink
            )
          ) {
            purchase.additionalDownloads.push(additionalDownload);
          }
        }
      });

      purchases.push(purchase);
    });

    const sortedPurchases = [...purchases].sort(
      (a, b) => b.orderDate.getTime() - a.orderDate.getTime()
    );

    return sortedPurchases;
  }

  return [];
};

export const getPurchaseBackground = (props: { purchases: PurchaseType[] }) => {
  let purchaseBackground = null;

  for (const purchase of props.purchases) {
    if (purchaseBackground) {
      break;
    }
    for (const product of purchase?.productsWithDownloadLinks) {
      const productWithFullData = products.find((p) => p.id === product.id);

      if (productWithFullData?.background) {
        purchaseBackground = productWithFullData.background;
        break;
      }
    }
  }

  return purchaseBackground;
};
