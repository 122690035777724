import { ProductGroupType } from "types";

const productGroups: {
  misc: ProductGroupType;
  ironMan: ProductGroupType;
  starWars: ProductGroupType;
  harryPotter: ProductGroupType;
} = {
  starWars: {
    id: "star-wars",
    name: "Star Wars",
    description: "",
  },
  ironMan: {
    id: "iron-man",
    name: "Iron Man",
    description: "",
  },
  misc: {
    id: "misc",
    name: "Miscellaneous",
    description: "",
  },
  harryPotter: {
    id: "harry-potter",
    name: "Harry Potter",
    description: "",
  },
};
export default productGroups;
