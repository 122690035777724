import Navigation from "./Navigation";
import Section from "./Section";
import styled from "styled-components";
import { useEffect } from "react";

const Wrapper = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
`;

const Home = (props: {
  sections: { label: string; component: any; id: string }[];
  activeId: string;
  setActiveId: (id: string) => void;
  scrollId: string;
  setScrollId: (id: string) => void;
}) => {
  useEffect(() => {
    // reset after each click for repeated navigation
    props.setScrollId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scrollId]);

  return (
    <Wrapper>
      <Navigation
        sections={props.sections.map((section) => {
          return {
            label: section.label,
            id: section.id,
          };
        })}
        activeId={props.activeId}
        onItemClick={(id) => props.setScrollId(id)}
      />

      {props.sections.map((section, i) => (
        <div key={i}>
          <Section
            {...section}
            scrollId={props.scrollId}
            onVisibleChange={(id: string, isVisible: boolean) =>
              isVisible && props.setActiveId(id)
            }
          />
        </div>
      ))}
    </Wrapper>
  );
};

export default Home;
