import { ProductType } from "types";
import background from "./background.jpg";
import fileFormats from "assets/misc/fileFormats";
import { importAll } from "utils";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1W8wuf6GHVVZnbUdW5K4kYsEGemi7xyZ-/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Azazel-Fx-Pack",
  name: "Azazel Fx-Pack",
  description: `The product contains footage with ten random fire animations and four different 'vapor cloud' effects. There are also four sound enclosed, plus a test footage and a tutorial on how to put everything together in Adobe After Effects.`,

  price: 15,
  softwareRequired: [],

  categories: [productCategories.visualEffect],
  groups: [productGroups.misc],
  license: licenses.commercial,

  images: gallery,
  videos: [{ src: "https://www.youtube.com/watch?v=WQifMOoiqj4", note: "" }],

  contents: [
    { text: "10x Fire simulation", format: fileFormats.mov },
    { text: "4x Smoke simulation", format: fileFormats.psd },
    { text: "4 Sound effects", format: fileFormats.mp3 },
    { text: "Test Footage", format: fileFormats.wmv },
    {
      text: "Teleportation Tutorial for Adobe After Effects",
      format: fileFormats.pdf,
    },
  ],
  tags: ["2D Effect"],
};

export default data;
