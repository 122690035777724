import { ProductType } from "types";
import background from "./background.jpg";
import fileFormats from "assets/misc/fileFormats";
import { importAll } from "utils";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1Gx0rWwGON8Vll6zf3WekQtbocUWBmJin/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Bionic-Arm",
  name: "Bionic Arm",
  description: `The product contains a .blend file with rigged, textured and uv unwrapped 3D model of the bionic arm with amazing level of details. It is ready for animation and compositing. You can use either the IK or the FK rig. Materials are designed for the 'Cycles' render engine. There are also 3 versions of battle damage - all in one .blend file! In case you don't have much experience with Blender, there is a short guide enclosed explaining all the tricky things about Blender and this project (faster rendering, adjusting materials, adding battle damage and much more).`,

  price: 39,
  previousPrice: 49,
  softwareRequired: ["Blender 2.69"],

  categories: [productCategories.rigged3DModel],
  groups: [productGroups.misc],
  license: licenses.commercial,

  images: gallery,
  videos: [{ src: "https://www.youtube.com/watch?v=h17oaz-C_DA", note: "" }],

  contents: [
    { text: "Bionic Arm", format: fileFormats.blend },
    { text: "Product Manual", format: fileFormats.pdf },
    {
      text: "Files required by the 3D project, UV textures, for example",
      format: fileFormats.files,
    },
    {
      text: "about",
      isDivider: true,
    },
    { text: "Made for Cycles render engine" },
  ],
  tags: ["Rigged", "Cycles Materials", "UV Textures"],
};

export default data;
