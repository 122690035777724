import { GalleryImageType } from 'components/Media/Gallery'

export const importAll = require =>
  require.keys().reduce((acc, next) => {
    acc[next.replace('./', '')] = require(next)
    return acc
  }, {})

export const transformImages = images =>
  Object.entries(images).map(([key, value]) => {
    return { src: (value as any).default, name: key }
  }) as GalleryImageType[]
