import Mark23DModel from "assets/products/individual/Mark-2-3D-Model";
import Mark2Blueprints from "assets/products/individual/Mark-2-Blueprints";
import Mark2HUD from "assets/products/aae-hud-factory-v2/Mark-2-HUD";
import Mark2Suit from "assets/products/individual/Mark-2-Suit";
import Mark2SuitCycles from "assets/products/individual/Mark-2-Suit-Cycles";
import Mark33DModel from "assets/products/individual/Mark-3-3D-Model";
import Mark3Blueprints from "assets/products/individual/Mark-3-Blueprints";
import Mark3HUD from "assets/products/aae-hud-factory-v2/Mark-3-HUD";
import Mark3Suit from "assets/products/individual/Mark-3-Suit";
import Mark3SuitCycles from "assets/products/individual/Mark-3-Suit-Cycles";
import Mark43DModel from "assets/products/individual/Mark-4-3D-Model";
import Mark4Blueprints from "assets/products/individual/Mark-4-Blueprints";
import Mark4HUD from "assets/products/aae-hud-factory-v2/Mark-4-HUD";
import Mark4Suit from "assets/products/individual/Mark-4-Suit";
import Mark4SuitCycles from "assets/products/individual/Mark-4-Suit-Cycles";
import Mark53DModel from "assets/products/individual/Mark-5-3D-Model";
import Mark5Blueprints from "assets/products/individual/Mark-5-Blueprints";
import Mark5HUD from "assets/products/aae-hud-factory-v2/Mark-5-HUD";
import Mark5Suit from "assets/products/individual/Mark-5-Suit";
import Mark63DModel from "assets/products/individual/Mark-6-3D-Model";
import Mark6Blueprints from "assets/products/individual/Mark-6-Blueprints";
import Mark6HUD from "assets/products/aae-hud-factory-v2/Mark-6-HUD";
import Mark6Suit from "assets/products/individual/Mark-6-Suit";
import { ProductBundleType } from "types";
import { createBundle } from "../bundle-generator";

const bundles: ProductBundleType[] = [
  createBundle({
    id: "Mark-2-Suit-With-HUD",
    name: "Mark 2 Suit + HUD",
    generatedThumbnail: {
      scale: 0.8,
    },
    products: [Mark2Suit, Mark2HUD],
  }),

  createBundle({
    id: "Mark-2-Suit-Cycles-With-HUD",
    name: "Mark 2 Suit Cycles + HUD",
    generatedThumbnail: {
      scale: 0.8,
    },
    products: [Mark2SuitCycles, Mark2HUD],
  }),

  createBundle({
    id: "Mark-3-Suit-With-HUD",
    name: "Mark 3 Suit + HUD",
    generatedThumbnail: {
      scale: 0.8,
    },
    products: [Mark3Suit, Mark3HUD],
  }),

  createBundle({
    id: "Mark-3-Suit-Cycles-With-HUD",
    name: "Mark 3 Suit Cycles + HUD",
    generatedThumbnail: {
      scale: 0.8,
    },
    products: [Mark3SuitCycles, Mark3HUD],
  }),

  createBundle({
    id: "Mark-4-Suit-With-HUD",
    name: "Mark 4 Suit + HUD",
    generatedThumbnail: {
      scale: 0.8,
    },
    products: [Mark4SuitCycles, Mark4HUD],
  }),

  createBundle({
    id: "Mark-4-Suit-Cycles-With-HUD",
    name: "Mark 4 Suit Cycles + HUD",
    generatedThumbnail: {
      scale: 0.8,
    },
    products: [Mark4SuitCycles, Mark4HUD],
  }),

  createBundle({
    id: "Mark-5-Suit-With-HUD",
    name: "Mark 5 Suit + HUD",
    generatedThumbnail: {
      scale: 0.8,
    },
    products: [Mark5Suit, Mark5HUD],
  }),

  createBundle({
    id: "Mark-6-Suit-With-HUD",
    name: "Mark 6 Suit + HUD",
    generatedThumbnail: {
      scale: 0.8,
    },
    products: [Mark6Suit, Mark6HUD],
  }),

  createBundle({
    id: "Mark-2-4-Suits-Cycles",
    name: "Mark 2-4 Suits Cycles",
    generatedThumbnail: {
      scale: 0.8,
    },
    products: [Mark2SuitCycles, Mark3SuitCycles, Mark4SuitCycles],
    discountPercentage: 35,
  }),

  createBundle({
    id: "Mark-2-6-Suits",
    name: "Mark 2-6 Suits",
    generatedThumbnail: {
      scale: 0.8,
    },
    products: [Mark2Suit, Mark3Suit, Mark4Suit, Mark5Suit, Mark6Suit],
    discountPercentage: 35,
  }),

  createBundle({
    id: "Mark-2-6-3D-Models",
    name: "Mark 2-6 3D-Models",
    generatedThumbnail: {
      scale: 0.8,
    },
    products: [
      Mark23DModel,
      Mark33DModel,
      Mark43DModel,
      Mark53DModel,
      Mark63DModel,
    ],
  }),

  createBundle({
    id: "Mark-2-6-Blueprints",
    name: "Mark 2-6 Blueprints",
    generatedThumbnail: {
      scale: 0.8,
    },
    products: [
      Mark2Blueprints,
      Mark3Blueprints,
      Mark4Blueprints,
      Mark5Blueprints,
      Mark6Blueprints,
    ],
    discountPercentage: 35,
  }),
];

export default bundles;
