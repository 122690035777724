import { ProductType } from "types";
import background from "./background.jpg";
import fileFormats from "assets/misc/fileFormats";
import { importAll } from "utils";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1vXcqVMEJV0ilorhAwVdUAfNB_e8fGGal/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Golden-Snitch-3D-Model",
  name: "The Golden Snitch (3D Model)",
  description: `The product contains a 3d model of the golden snitch, which can be used in your animations. The model is exported to .obj file, so it can be used in common 3d programs, such as 3Ds Max, Cinema 4D, Maya, Blender etc.`,

  price: 10,
  softwareRequired: [],

  categories: [productCategories.file3DModel],
  groups: [productGroups.harryPotter],
  license: licenses.editorial,

  images: gallery,
  videos: [],

  contents: [
    { text: "3D model - The Golden Snitch", format: fileFormats.obj },
    {
      text: "about",
      isDivider: true,
    },
    { text: "No rig, materials or textures" },
  ],
  tags: ["3D Model"],
};

export default data;
