import styled, { keyframes } from 'styled-components'

import { CaretTop } from '@styled-icons/open-iconic/'
import useScrollAppear from 'hooks/useScrollAppear'

const ScrollTopAnimation = keyframes`
  from { 
    bottom: -200px;
  }
  to { 
    bottom: 0px;
  }
`

const Wrapper = styled.div`
  animation-duration: 0.8s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-name: ${ScrollTopAnimation};

  position: fixed;
  bottom: 0;
  right: 10vw;
  margin-bottom: 6vh;

  color: ${(props) => props.theme.white}88;
  transition: all 0.1s ease;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.white}AA;
  }

  ${(props) => props.theme.breakpoint.M} {
    left: 0;
    width: 100%;
    margin-bottom: 2vh;
    display: flex;
    justify-content: center;
  }

  pointer-events: none;
  button {
    pointer-events: auto;
  }
`

const ButtonScrollToTop = () => {
  const scrollAppear = useScrollAppear({ triggerHeightPercentage: 50 })

  return scrollAppear ? (
    <Wrapper>
      <button
        className="btn-icon-only"
        onClick={() =>
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        }
      >
        <CaretTop />
      </button>
    </Wrapper>
  ) : (
    <></>
  )
}

export default ButtonScrollToTop
