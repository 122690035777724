import { ProductType } from "types";
import background from "./background.jpg";
import fileFormats from "assets/misc/fileFormats";
import { importAll } from "utils";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1iQn94fGva1tyniHi50CSKybixl8L7c28/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Golden-Snitch-Rigged",
  name: "The Golden Snitch (Rigged)",
  description: `The product contains a 3d model of the golden snitch, which can be used in your animations. The model is exported to .obj file, so it can be used in common 3d programs, such as 3Ds Max, Cinema 4D, Maya, Blender etc. This version contains also a .blend file with the model attached to a rig ready for animation.`,

  price: 15,
  softwareRequired: ["Blender 2.81a"],

  categories: [productCategories.rigged3DModel],
  groups: [productGroups.harryPotter],
  license: licenses.editorial,

  images: gallery,
  videos: [],

  contents: [
    { text: "Rigged Golden Snitch", format: fileFormats.blend },
    { text: "3D model - The Golden Snitch", format: fileFormats.obj },
    {
      text: "about",
      isDivider: true,
    },
    { text: "Very simple material and rig, No textures" },
  ],
  tags: ["Rigged", "Simple Material"],
};

export default data;
