import styled, { css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useImageLoaded, useOnScreen, useScrollPercentage } from "hooks";

const Wrapper = styled.div`
  flex: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 4vw;
`;

const Background = styled.div<{
  isLoaded?: boolean;
  isOnScreen?: boolean;
  fadePercentage?: number;
  scrollPercentage?: number;
  cssOnScroll?: (scrollPercentage: number) => string;
  mobileScale?: number;
  opacity?: number;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  opacity: ${(props) => (props.opacity || 1) * 0.5};
  ${(props) => props.theme.breakpoint.M} {
    opacity: ${(props) => (props.opacity || 1) * 0.75};
  }

  ${(props) =>
    props.cssOnScroll
      ? css`
          ${props.cssOnScroll(props.scrollPercentage)};
        `
      : css``}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.8s ease;

    --scale: 1;
    --scale-preload: 1.05;

    ${(props) => props.theme.breakpoint.M} {
      ${(props) =>
        props.mobileScale &&
        css`
          --scale: ${(props) => props.mobileScale || 1};
          --scale-preload: ${(props) => props.mobileScale || 1.05};
        `}
    }

    transform: scale(var(--scale));

    ${(props) =>
      props.isLoaded
        ? css`
            opacity: 1;
          `
        : css`
            opacity: 0;
            transform: scale(var(--scale-preload));
          `}

    ${(props) =>
      props.isOnScreen
        ? css`
            opacity: 1;
          `
        : css`
            opacity: 0.25;
          `}
  }

  --fadePercentage: ${(props) =>
    props.fadePercentage ? props.fadePercentage / 2 : 15}%;

  -webkit-mask-image: linear-gradient(
    transparent,
    #000 calc(var(--fadePercentage)),
    #000 calc(100% - var(--fadePercentage)),
    transparent
  );
  mask-image: linear-gradient(
    transparent,
    #000 calc(var(--fadePercentage)),
    #000 calc(100% - var(--fadePercentage)),
    transparent
  );
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-around;
`;

const LayoutFullscreen = (props: {
  children: any;
  background?: any;
  backgroundOpacity?: number; // 0 - 1
  fadePercentage?: number;
  mobileScale?: number;
  cssOnScroll?: (scrollPercentage: number) => string;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOnScreen, setIsOnScreen] = useState(false);
  const { imageRef, imageLoaded } = useImageLoaded();

  const refOnScreen = useRef();
  const onScreen = useOnScreen(refOnScreen, "-20%");
  const scrollPercentage = useScrollPercentage({
    elementHeightRef: refOnScreen,
  });

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    setIsOnScreen(onScreen);
  }, [onScreen]);

  return (
    <Wrapper ref={refOnScreen}>
      {props.background && (
        <Background
          isLoaded={isLoaded && imageLoaded}
          isOnScreen={isOnScreen}
          fadePercentage={props.fadePercentage}
          scrollPercentage={scrollPercentage}
          cssOnScroll={props.cssOnScroll}
          mobileScale={props.mobileScale}
          opacity={props.backgroundOpacity}
        >
          <img src={props.background} alt="" ref={imageRef} />
        </Background>
      )}
      <Content>{props.children}</Content>
    </Wrapper>
  );
};

export default LayoutFullscreen;
