import { AnyProductType } from 'types'

//
// ADD TO CART
//
export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST'
export const ADD_TO_CART_SUCCEED = 'ADD_TO_CART_SUCCEED'
export const ADD_TO_CART_FAILED = 'ADD_TO_CART_FAILED'

type AddToCartRequestType = {
  type: typeof ADD_TO_CART_REQUEST
  payload: AnyProductType
}
export const addToCart = (payload): AddToCartRequestType => ({
  type: ADD_TO_CART_REQUEST,
  payload,
})

type AddToCartSucceedType = {
  type: typeof ADD_TO_CART_SUCCEED
  payload: AnyProductType
}
export const addToCartSucceed = (payload): AddToCartSucceedType => ({
  type: ADD_TO_CART_SUCCEED,
  payload,
})

type AddToCartFailedType = {
  type: typeof ADD_TO_CART_FAILED
}
export const addToCartFailed = (): AddToCartFailedType => ({
  type: ADD_TO_CART_FAILED,
})

//
// REMOVE FROM CART
//
export const REMOVE_FROM_CART_REQUEST = 'REMOVE_FROM_CART_REQUEST'
export const REMOVE_FROM_CART_SUCCEED = 'REMOVE_FROM_CART_SUCCEED'
export const REMOVE_FROM_CART_FAILED = 'REMOVE_FROM_CART_FAILED'

type RemoveFromCartRequestType = {
  type: typeof REMOVE_FROM_CART_REQUEST
  payload: AnyProductType
}
export const removeFromCart = (payload): RemoveFromCartRequestType => ({
  type: REMOVE_FROM_CART_REQUEST,
  payload,
})

type RemoveFromCartSucceedType = {
  type: typeof REMOVE_FROM_CART_SUCCEED
  payload: AnyProductType
}
export const removeFromCartSucceed = (payload): RemoveFromCartSucceedType => ({
  type: REMOVE_FROM_CART_SUCCEED,
  payload,
})

type RemoveFromCartFailedType = {
  type: typeof REMOVE_FROM_CART_FAILED
}
export const removeFromCartFailed = (): RemoveFromCartFailedType => ({
  type: REMOVE_FROM_CART_FAILED,
})

//
// CLEAR CART
//
export const CLEAR_CART_REQUEST = 'CLEAR_CART_REQUEST'
export const CLEAR_CART_SUCCEED = 'CLEAR_CART_SUCCEED'
export const CLEAR_CART_FAILED = 'CLEAR_CART_FAILED'

type ClearCartRequestType = {
  type: typeof CLEAR_CART_REQUEST
}
export const clearCart = (): ClearCartRequestType => ({
  type: CLEAR_CART_REQUEST,
})

type ClearCartSucceedType = {
  type: typeof CLEAR_CART_SUCCEED
}
export const clearCartSucceed = (): ClearCartSucceedType => ({
  type: CLEAR_CART_SUCCEED,
})

type ClearCartFailedType = {
  type: typeof CLEAR_CART_FAILED
}
export const clearCartFailed = (): ClearCartFailedType => ({
  type: CLEAR_CART_FAILED,
})

//
// ALL TYPES
//
export type CartActionTypes =
  | AddToCartRequestType
  | AddToCartSucceedType
  | AddToCartFailedType
  | RemoveFromCartRequestType
  | RemoveFromCartSucceedType
  | RemoveFromCartFailedType
  | ClearCartRequestType
  | ClearCartSucceedType
  | ClearCartFailedType
