import { ProductType } from "types";
import background from "./background.jpg";
import fileFormats from "assets/misc/fileFormats";
import { importAll } from "utils";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1kVETTA9531rILM1LGxQGJD3l1RqQldvY/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Skeleton-3D-Model",
  name: "Skeleton 3D Model",
  description: `The product contains a 3d model of the skeleton, which can be used in graphical user interfaces for medical animations, diagnosis and much more. The model is exported to .obj file, so it can be used in common 3d programs, such as 3Ds Max, Cinema 4D, Maya, Blender etc. There are three .obj files, each one with different subdivision level. The model does NOT contain the rig that you can see in the demo video, you can find the rig inside the 'rigged' version of this product.`,

  price: 25,
  softwareRequired: [],

  categories: [productCategories.file3DModel],
  groups: [productGroups.misc],
  license: licenses.commercial,

  images: gallery,
  videos: [
    {
      src: "https://www.youtube.com/watch?v=rx1Ct7_-aQM",
      note: "The rig is not included in this product, only the 3D model is included.",
    },
  ],

  contents: [
    {
      text: "3D model - Skeleton - subdivision level 1",
      format: fileFormats.obj,
    },
    {
      text: "3D model - Skeleton - subdivision level 2",
      format: fileFormats.obj,
    },
    {
      text: "3D model - Skeleton - subdivision level 3",
      format: fileFormats.obj,
    },
    {
      text: "about",
      isDivider: true,
    },
    { text: "No rig, materials or textures" },
  ],
  tags: ["3D Model"],
};

export default data;
