import { ProductAAEHUDFactoryType } from "types";
import background from "./background.jpg";
import backgroundVideo from "./background-video.mp4";
import { importAll } from "utils";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

const id = "HUD-Effects";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

let widgetImages = [];
try {
  widgetImages = importAll(
    (require as any).context("./widgets", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductAAEHUDFactoryType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1-gB2Wi4MGiQ4af0o9bx9xRI_ixwM-Ccy/view?usp=sharing",
  },
  thumbnail,
  background,
  backgroundVideo,
  isAAEHUDFactory: true,
  isAdditionalProduct: true,
  id: id,
  name: "HUD Effects",

  price: 29,
  softwareRequired: ["AAE HUD Factory v2.0 HUD"],

  categories: [productCategories.hud],
  groups: [productGroups.ironMan],
  license: licenses.commercial,

  images: gallery,
  videos: [],

  tags: [`${Object.keys(widgetImages).length} HUD Effects`, "for rich scenes"],

  widgets: !widgetImages
    ? []
    : Object.entries(widgetImages).map((entries) => {
        const filename = entries[0].replace(`${id}-`, "").replace(".jpg", "");
        return { name: filename, src: entries[1].default };
      }),
};

export default data;
