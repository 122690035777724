import AAEFireFx from "assets/products/individual/AAE-Fire-Fx";
import AzazelFxPack from "assets/products/individual/Azazel-Fx-Pack";
import NightcrawlerFxPack from "assets/products/individual/Nightcrawler-Fx-Pack";
import { ProductBundleType } from "types";
import background from "./background.jpg";
import { createBundle } from "../bundle-generator";
import thumbnail from "./thumbnail.png";

const data: ProductBundleType = createBundle({
  id: "AAE-Pack",
  name: "AAE Pack",
  thumbnail,
  background,
  products: [AAEFireFx, AzazelFxPack, NightcrawlerFxPack],
});

export default data;
