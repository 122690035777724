import { ProductType } from "types";
import background from "./background.jpg";
import fileFormats from "assets/misc/fileFormats";
import { importAll } from "utils";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1COLYTPRhrbwIxK1E_ovxfCNUftTRE7yQ/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Barret-M82a1-Remote",
  name: "Barrett M82a1 Remote",
  description: `The product contains a .blend file with a 3D model of the Barret M82A1 sniper rifle with a very high level of detail (screws, wires, ...) and with a sci-fi remote control tripod. There is a basic rig allowing you to animate the gun and the tripod. There are no materials.`,

  price: 10,
  softwareRequired: ["Blender"],

  categories: [productCategories.rigged3DModel],
  groups: [productGroups.misc],
  license: licenses.commercial,

  images: gallery,
  videos: [{ src: "https://www.youtube.com/watch?v=KJ6K7vPqYq0", note: "" }],

  contents: [
    { text: "Barrett M82a1 Remote", format: fileFormats.blend },
    {
      text: "about",
      isDivider: true,
    },
    { text: "Simple rig included" },
    { text: "No materials or textures" },
  ],
  tags: ["3D Model", "Simple Rig"],
};

export default data;
