export const common: {
  productDeliveryHours: number
  contact: {
    email: string
    facebook: string
    youtube: string
    instagram: string
    deviantArt: string
  }
} = {
  productDeliveryHours: 24,
  contact: {
    email: 'jan.hamernik@gmail.com',
    facebook: 'https://www.facebook.com/Jan-Hamernik-VFx-182107465186499/',
    youtube: 'https://www.youtube.com/channel/UCErqbffC6dqENt2DSeKsikw',
    instagram: 'https://www.instagram.com/jan_hamernik_props/',
    deviantArt: 'https://www.deviantart.com/jan-hamernik',
  },
}
