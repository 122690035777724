//
// LOCAL STORAGE
//
export const getLocalStorageItemName = () => {
  return `pass-${process.env.REACT_APP_ENVIRONMENT}`.toLowerCase();
};

export const savePassword = (pass) => {
  localStorage.setItem(getLocalStorageItemName(), pass);
};

export const getSavedPassword = () => {
  return localStorage.getItem(getLocalStorageItemName());
};

export const removeSavedPassword = () => {
  localStorage.removeItem(getLocalStorageItemName());
};

//
// ENCODING
//
export const encodePassword = (password) => {
  return btoa(password);
};

//
// AUTH
//
export const requiresAuth = () => {
  return process.env.REACT_APP_PASSWORD?.length > 0;
};

export const isValidEncodedPassword = (encodedPassword) => {
  return encodedPassword === encodePassword(process.env.REACT_APP_PASSWORD);
};

export const isValidPassword = (password) => {
  return (
    encodePassword(password) === encodePassword(process.env.REACT_APP_PASSWORD)
  );
};
