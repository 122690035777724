import {
  PayPalActionTypes,
  ADD_PAYPAL_ORDER_SUCCEED,
  REMOVE_PAYPAL_ORDER_SUCCEED,
} from './actions'

import { PayPalOrderType, PayPalOrderStoredType } from 'types'

const initialState: { orders: PayPalOrderStoredType[] } = {
  orders: [],
}

const reducer = (state = initialState, action: PayPalActionTypes) => {
  switch (action.type) {
    case ADD_PAYPAL_ORDER_SUCCEED:
      const order: PayPalOrderType = action.payload
      return {
        ...state,
        orders: [
          ...state.orders,
          {
            id: order.id,
            create_time: new Date(),
            payer_name: `${order.payer.name.given_name} ${order.payer.name.surname}`,
            payer_email: order.payer.email_address,
            products: order.products,
          } as PayPalOrderStoredType,
        ],
      }

    case REMOVE_PAYPAL_ORDER_SUCCEED:
      return {
        ...state,
        orders: [
          ...state.orders.filter(order => order.id !== action.payload.id),
        ],
      }

    default:
      return state
  }
}

export default reducer
