import { LicenseType } from "types";

const licenses: {
  personal: LicenseType;
  commercial: LicenseType;
  editorial: LicenseType;
} = {
  personal: {
    id: "personal",
    name: "Non-Commercial License",
    usage: "For Personal Use Only",
    description:
      "This product is licensed for personal use only. It is not allowed to sell digital or physical (e.g. 3D printed) copies of the product, or its derivatives, or to distribute the files.",
  },
  commercial: {
    id: "commercial",
    name: "Royalty-Free License",
    usage: "Commercial Use Allowed",
    description:
      "This product is licensed for use in personal and commercial projects, including but not limited to videos and advertising. You are not allowed to resell the product or any of its derivatives.",
  },
  editorial: {
    id: "editorial",
    name: "Royalty-Free License",
    usage: "Editorial Uses Only",
    description:
      "The intellectual property depicted in this product is not affiliated with or endorsed by the original rights holders and must be used under editorial use restrictions.",
  },
};

export default licenses;
