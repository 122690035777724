export const createDate = (props: {
  day: number;
  month: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  year: number;
}) => {
  return new Date(props.year, props.month - 1, props.day);
};

export const checkIsNew = (date: Date) => {
  if (!date) {
    return false;
  }
  const today = new Date();
  var differenceTime = today.getTime() - date.getTime();
  var differenceDays = differenceTime / (1000 * 3600 * 24);

  return differenceDays < 7 * 4;
};

export const formatDate = (dateInput: string | Date): string => {
  const dateObj =
    typeof dateInput === "string" ? new Date(dateInput) : dateInput;

  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(dateObj.getDate()).padStart(2, "0");
  const hours = String(dateObj.getHours()).padStart(2, "0");
  const minutes = String(dateObj.getMinutes()).padStart(2, "0");

  return `${year}/${month}/${day} ${hours}:${minutes} UTC`;
};
